import './EsimBundleSearchForm.scss';

import { Resource } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import Typeahead from '../Typeahead';

interface Props {
  className: string;
  countries: Resource[];
  selectedCountry: Resource | null;
  onChange: (country: Resource | null) => void;
}

const EsimBundleSearchForm = ({
  className,
  countries,
  selectedCountry,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}__search-typeahead`}>
      <Typeahead
        data={countries}
        selectedOption={selectedCountry}
        onChange={onChange}
        placeholder={t(
          'landing.search.countries_placeholder',
          'Enter your destination',
        )}
        countries
        emptyFilterMessage={t(
          'landing.search.countries_empty',
          'No countries with plans available',
        )}
      />
    </div>
  );
};

export default EsimBundleSearchForm;
